<template>
  <v-container fluid>
    <div class="main">

      <div class="text-end mb-3">
        <v-btn
            class="gifty white--text rounded-lg"
            @click="addNotice"
            depressed
        >
          <v-icon left>mdi-plus</v-icon>
          Nouvelle Notification
        </v-btn>
      </div>

      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-card-title>
              <div>
                <v-text-field
                    v-model="filter.keyword"
                    append-icon="mdi-magnify"
                    clearable
                    dense
                    filled
                    hide-details
                    placeholder="Rechercher..."
                    rounded
                    single-line
                />
              </div>
              <v-spacer/>
              <filter-notice-dialog @filter="handleFilter($event)"/>
              <v-btn
                  text
                  @click="refresh"
                  elevation="0"
                  class="grey--text ml-2"
              >
                <v-icon left>mdi-refresh</v-icon>
                Mettre à jour
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-0">

              <v-skeleton-loader
                  type="table"
                  v-if="isLoading"
              ></v-skeleton-loader>

              <div v-else>
                <div v-if="notices.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Title Ar</th>
                        <th class="text-no-wrap">Date de création</th>
                        <th class="text-no-wrap">Date d'envoi</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in notices.data" :key="item.id">

                        <td>
                          <v-img class="rounded grey lighten-4"
                                 :src="getImageLink(item.image)"
                                 width="35"
                                 height="35"
                          ></v-img>
                        </td>

                        <td class="text-no-wrap">
                          {{ item.title }}
                        </td>

                        <td class="text-no-wrap">
                          {{ item.title_ar }}
                        </td>

                        <td class="text-no-wrap">
                          {{ moment(item.created_at).format("YYYY-MM-DD HH:mm") }}
                        </td>

                        <td class="text-no-wrap">
                          {{ formatSendAt(item) }}
                        </td>

                        <td>
                          <div class="d-flex align-center">
                            <v-tooltip top v-if="item.editable">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="blue--text"
                                    icon
                                    v-if="item.editable"
                                    @click="updateNotice(item)"
                                >
                                  <v-icon
                                  >mdi-pencil
                                  </v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Modifier</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="deleteNotice(item)"
                                >
                                  <v-icon class="error--text"
                                  >mdi-delete
                                  </v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Supprimer</span>
                            </v-tooltip>

                            <v-tooltip top color="blue">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="clone(item)"
                                >
                                  <v-icon
                                  >mdi-content-copy
                                  </v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Copier</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider/>
                </div>

                <div v-else>
                  <div class="text-center pa-10">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>


              </div>
            </v-card-text>

            <v-card-actions v-if="notices && notices.data && notices.data.length">
               <div :style="{width : '150px'}">
                 <v-select outlined
                           hide-details
                           label="Lignes par page"
                           v-model="filter.size"
                           dense
                           :items="[10, 20, 30, 40, 50]"
                           @change="fetchData"
                 ></v-select>
               </div>
              <v-spacer/>
              <v-pagination
                  total-visible="5"
                  v-if="notices.total > filter.size"
                  circle
                  v-model="filter.page"
                  :length="Math.ceil(notices.total / filter.size)"
                  @input="fetchData"
              ></v-pagination>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
    </div>

    <NoticeForm ref="noticeForm" @refresh="fetchData()"/>

    <DeleteNotice ref="deleteNotice" @refresh="refresh()"/>

  </v-container>
</template>

<script>
import {HTTP} from "@/http-common";
import NoticeForm from "./components/NoticeForm.vue";

import DeleteNotice from "./components/DeleteNotice.vue";
import moment from "moment";

import FilterNoticeDialog from "./components/FilterNoticeDialog.vue";

export default {
  components: {NoticeForm, DeleteNotice, FilterNoticeDialog},
  data() {
    return {
      isLoading: true,
      filter: {
        size: 10,
        page: 1,
        keyword: "",
      },

      notices: {},
    };
  },
  methods: {
    formatSendAt(item) {
      if (item.send_at) {
        return item.send_day + " " + item.send_hour;
      }
      return "Date de création";
    },

    fetchData() {
      this.isLoading = true;
      HTTP.get("/v1/notices", {
        params: {
          ...this.filter,
        },
      })
          .then((res) => {
            this.notices = res.data.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
    },
    refresh() {
      this.filter.page = 1;
      this.filter.keyword = "";
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.filter.sendDate = "";
      this.fetchData();
    },
    addNotice() {
      this.$refs.noticeForm.open();
    },
    updateNotice(item) {
      this.$refs.noticeForm.open(item);
    },
    deleteNotice(item) {
      this.$refs.deleteNotice.open(item);
    },

    clone(item) {
      this.$refs.noticeForm.clone(item);
    },

    handleFilter(event) {
      this.filter.startDate = event.startDate;
      this.filter.endDate = event.endDate;
      this.filter.sendDate = event.sendDate;

      this.fetchData();
    },
  },
  computed: {
    keyword() {
      return this.filter.keyword;
    },
  },
  watch: {
    keyword() {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
</style>